import React from 'react';
import RenderDetailsForm from '../RenderDetailsForm';
import PreviousRendersSection from '../PreviousRendersSection';

function MainContent() {
  return (
    <main className="main">
      <div className="container">
      <RenderDetailsForm />
      <PreviousRendersSection />
      </div>
    </main>
  )
}

export default MainContent;
