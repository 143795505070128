import { debounce } from 'lodash';
import { saveEmailToDatabase } from '../../api';

export const saveEmailDebounce = debounce((
  {
    email,
    values,
    renderZipPath,
    jobId,
  }:
    {
      email: string,
      values: any,
      renderZipPath: string,
      jobId: string
    }
) => {
    saveEmailToDatabase({
      email,
      values,
      renderZipPath,
      jobId,
    });
}, 1200);

export const saveEmailToLocalStorage = (email: string) => {
  localStorage.setItem('email', email);
};
