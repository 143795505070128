import React from 'react';
import logoSrc from '../../images/gpxoverlay-logo-normal.svg';
import howItWorksIllustrationSrc from '../../images/gpxoverlay-howitworks-illustration.min.png';
import { Tabs, List, Alert } from "flowbite-react";
import { BUILD_DATE } from '../utils/env';

function Header() {
  return (
    <header className="header">
      <div className="container">
        <img className="logo" src={logoSrc} alt="gpxoverlay logo" />
        <img className="illustration_image" src={howItWorksIllustrationSrc} alt="gpxoverlay logo" />
      </div>

      <div className="container">
        <Tabs aria-label="Tabs with icons" variant="underline" color="primary">
          <Tabs.Item active title="How to use?">
            <div className="text-gray-600 pb-3">
              Compatible with <b>Davinci Resolve, Adobe Premiere Pro</b> and other apps that allows to upload png files as a photo sequence.
            </div>

            <List ordered>
              <List.Item>Download .gpx (or .fit) from you activity from Garmin/Strava/or different device.</List.Item>
              <List.Item>Prepare GPX. Trim here: <a href="https://gpx.studio/" className='text-blue-600' target='_blank' rel="noreferrer">gpx.studio</a>; Convert fit to gpx here: <a href="https://fit-converter.com/" className='text-blue-600' target='_blank' rel="noreferrer">fit-converter.com</a></List.Item>
              <List.Item>Upload your activity in .GPX format here</List.Item>
              <List.Item>Choose video settings you use in your videeo editor app.</List.Item>
              <List.Item>Download rendered photos from this website.</List.Item>
              <List.Item>
                Upload them as a <b>photo sequence</b> in your video editor program. Tutorials:<br/>
                <List className='pl-10'>
                  <List.Item>
                      <a className='text-blue-600' target='_blank' href="https://jayaretv.com/tips/import-image-sequence-in-davinci-resolve/" rel="noreferrer"> Davinci Resolve</a>
                  </List.Item>
                  <List.Item>
                    <a className='text-blue-600' target='_blank' href="https://helpx.adobe.com/premiere-pro/using/importing-still-images.html#import_numbered_still_image_sequences_as_video_clips" rel="noreferrer"> Adobe Premiere Pro</a>
                  </List.Item>
                </List>
              </List.Item>
            </List>
          </Tabs.Item>
          <Tabs.Item title="How to prepare .gpx file?">
            <Alert color="gray" className='mb-4 text-xs italic text-blue-400'>
              <span className='flex'>
                <svg className="w-6 h-6 dark:text-white mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <span className='mt-1'>
                  This tool will allow to trim .GPX in the future. But for now you need to use 3rd party tool to prepare the .gpx.
                </span>
              </span>
            </Alert>
            <List>
              <List.Item>Use <a href="https://gpx.studio/" className='text-blue-600' target='_blank' rel="noreferrer">gpx.studio</a> to trim your gpx to match your video</List.Item>
              <List.Item>Use <a href="https://fit-converter.com/" className='text-blue-600' target='_blank' rel="noreferrer">fit-converter.com</a> if you need to convert .fit file to .gpx</List.Item>
            </List>
          </Tabs.Item>
          <Tabs.Item title="Video tutorial">
            <span className='text-gray-500'>
              (Video still in production...)
            </span>
          </Tabs.Item>
          <Tabs.Item title="Do you need more? | Contact">
            <List>
              <List.Item>This tool is in continuous development.</List.Item>
              <List.Item>Last update: {BUILD_DATE}</List.Item>
              <List.Item>If you need more features or need help, <a href="https://instagram.com/lensonbike.cc" className='text-blue-600' target='_blank' rel="noreferrer">text me here (Instagram: lensonbike.cc)</a></List.Item>
              <List.Item>Changelog:<br/>
                <List className='pl-10'>
                  <List.Item>12.10.2024 Allow to download previously generated overlays</List.Item>
                  <List.Item>07.10.2024 Fix grade and speed change after activity break. (First few seconds of activity)</List.Item>
                  <List.Item>27.09.2024 Add option to select miles or kilometers</List.Item>
                  <List.Item>24.09.2024 Reduce photo size for quick processing</List.Item>
                  <List.Item>23.09.2024 Improve stability. Should not crash while processing anymore</List.Item>
                  <List.Item>22.09.2024 Handle more edge cases of gpx file.</List.Item>
                  <List.Item>21.09.2024 Added interpolation to smoother speed on frames</List.Item>
                </List>
              </List.Item>
              {/* <List.Item>Buy me a coffee to support development:</List.Item> */}
            </List>
          </Tabs.Item>
        </Tabs>
      </div>
    </header>
  )
}

export default Header;
