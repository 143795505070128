import { firebaseDb } from '../firebase';
import { setDoc, doc, arrayUnion, updateDoc, getDoc } from "firebase/firestore";
import { FbUserRendersEntity, FbUserEntity } from '../types';
import { getApiUrl } from '../components/utils/url';
import axios from 'axios';
import { RenderProgress } from './api_types';

export const getUserRendersList = () => {}

export const getUserDoc = async (email: string) => {
  const docRef = doc(firebaseDb, "users", email);
  const docSnap = (await getDoc(docRef)).data();
  return docSnap as FbUserEntity;
}

export const saveEmailToDatabase = async ({
  email,
  values,
  renderZipPath,
  jobId,
  isDownloaded = false
}:
  {
    email: string,
    values: any, // TODO: Use Render type
    renderZipPath: string,
    jobId: string,
    isDownloaded?: boolean
  }
) => {
  const userDoc = await getUserDoc(email);
  const doesEmailExist = !!userDoc;

  const data: FbUserRendersEntity = {
    renderZipPath,
    time: new Date(),
    isDownloaded,
    jobId: jobId,
    render: {
      fps: values.fps,
      orientation: values.orientation,
      resolution: values.resolution,
      units: values.units,
    }
  }

  if (!doesEmailExist) {
    await setDoc(doc(firebaseDb, "users", email), {
      renders: [data],
    });
    return;
  }

  const indexToUpdate = userDoc?.renders.findIndex(({ renderZipPath: fbRenderZipPath }) => {
    return renderZipPath === fbRenderZipPath;
  });
  const renderAlreadyExistsInUserEntity = indexToUpdate > -1;
  const skipUpdate = userDoc.renders[indexToUpdate]?.isDownloaded;
  if (skipUpdate) {
    // Skip update when there is isDownloaded true already. It means, nothing can change at this point.
    // TODO: Add bugsnug warning about this
    return;
  }

  if (renderAlreadyExistsInUserEntity) {

    const newRenders = [
      ...userDoc.renders,
    ];

    newRenders[indexToUpdate] = data;

    await updateDoc(doc(firebaseDb, "users", email), {
      renders: newRenders
    });
  } else {
    await updateDoc(doc(firebaseDb, "users", email), {
      renders: arrayUnion(data)
    });
  }
}

export const getRenderProgress = async (jobId: string) => {
  const { data } = await axios.get(getApiUrl(`/render-progress?job_id=${jobId}`))
  return data as RenderProgress;
}
