import { getRenderProgress } from "../api";

export const checkRenderProgress = async (jobId: string, updateProgress: (progress: number, isFinished: boolean, isError?: true) => void) => {
  let a;
  try {
    a = await getRenderProgress(jobId);
  } catch {
    updateProgress(0, false, true); 
    return;
  }

  const { progress, is_finished: isFinished} = a;

  if (isFinished) {
    updateProgress(1, true)
  } else {
    updateProgress(progress, false)
    setTimeout(() => {
      checkRenderProgress(jobId, updateProgress);
    }, 1000);
  }
}
